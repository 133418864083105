"use strict";
exports.__esModule = true;
exports.SvgCircleWarning = void 0;
var React = require("react");
var SvgWrapper_1 = require("./../SvgWrapper");
/**
 * @deprecated This icon is deprecated. Please use a non-v2 alternative.
 */
var SvgCircleWarning = function (props) { return (<SvgWrapper_1.default {...props} aspectRatio={1}>
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <path fillRule="evenodd" clipRule="evenodd" d="M4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16ZM16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6ZM16 9C16.5523 9 17 9.44772 17 10L17 17.5C17 18.0523 16.5523 18.5 16 18.5C15.4477 18.5 15 18.0523 15 17.5L15 10C15 9.44772 15.4477 9 16 9Z" fill={props.color || "#10293C"}/>
          <path d="M17.5 22C17.5 22.8284 16.8284 23.5 16 23.5C15.1716 23.5 14.5 22.8284 14.5 22C14.5 21.1716 15.1716 20.5 16 20.5C16.8284 20.5 17.5 21.1716 17.5 22Z" fill={props.color || "#10293C"}/>
        </g>
      </g>
    </svg>
  </SvgWrapper_1.default>); };
exports.SvgCircleWarning = SvgCircleWarning;
exports["default"] = exports.SvgCircleWarning;
