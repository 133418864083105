import React, {FC, useState, useEffect, PropsWithChildren} from 'react';
import styled from '@emotion/styled';
import Color from 'color';
import {Colors} from '../Color';

import {Skeleton} from '../Skeleton';
import Theme from '../theme';
import {getMainFont} from '../helpers/getMainFont';

export type AvatarProps = PropsWithChildren<{
  /** Name of the user or alt-text for non-user avatars */
  name: string;
  /** Stop container from using title tag */
  disableTitle?: boolean;
  /** Image source for an image icon */
  src?: string;
  /** Should background color be generated based on the name */
  generateBackgroundColor?: boolean;
  /** Background color override */
  color?: string;
  /** px size for avatar width & height */
  size?: number;
  /** Allows external styling (e.g. styled(Avatar)`...`) */
  className?: string;
}>;

type AvatarContainerProps = {
  title?: string;
  bgColor?: string;
  fgColor?: string;
  size?: number;
};

const initials = (name: string): string => {
  const [firstName, lastName] = name.split(' ');
  return firstName && lastName
    ? `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
    : `${firstName.charAt(0).toUpperCase()}`;
};

const randomColorFromString = (str: string): string => {
  let hash = 0;
  if (str.length === 0) return hash.toString();
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let color = '#';
  for (let j = 0; j < 3; j++) {
    const value = (hash >> (j * 8)) & 255;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

// Get contrast color
const contrastColor = (hex: string): string => {
  const bg = Color(hex);
  if (bg.contrast(Color(Colors.White0)) > 4.5) {
    return Colors.White0;
  }
  return Colors.Green20;
};

const AvatarContainer = styled.div<AvatarContainerProps>`
  border-radius: 100%;
  border: 2px solid white;
  overflow: hidden;
  width: ${({size}: AvatarContainerProps) =>
    size ? size : Theme.space[8] + 2}px;
  height: ${({size}: AvatarContainerProps) =>
    size ? size : Theme.space[8] + 2}px;
  color: ${({fgColor}: AvatarContainerProps) => fgColor};
  background-color: ${({bgColor}: AvatarContainerProps) => bgColor};
  font-family: ${({theme}) => getMainFont(theme)};
  > img {
    object-fit: cover;
  }
`;

const AvatarInitials = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

export const Avatar: FC<AvatarProps> = ({
  src,
  name,
  disableTitle,
  color,
  size,
  children,
  className,
  generateBackgroundColor,
}) => {
  const avatarImage = new Image();
  const [imageLoading, setImageLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  /**
   * TODO: This should probably leverage the `Image` component, but
   * we'll need to modify the error handling behavior to pass a fallback.
   * thissholdn't be terribly complicated, but is above what we want to hit
   * for the MVP.
   */
  useEffect(() => {
    if (src) {
      avatarImage.onload = () => setImageLoading(false);
      avatarImage.onerror = () => {
        setImageError(true);
        setImageLoading(false);
      };
      avatarImage.src = src;
      setImageLoading(true);
    }
  }, [src]); // eslint-disable-line react-hooks/exhaustive-deps

  let bgColor, fgColor;

  // Checking the name prevents empty strings being passed
  if (generateBackgroundColor && !!name) {
    bgColor = randomColorFromString(name);
    fgColor = contrastColor(bgColor);
  } else {
    bgColor = Colors.Tan70;
    fgColor = Colors.Gray100;
  }

  let avatarContents: React.ReactNode = '';
  if (imageError) {
    avatarContents = <AvatarInitials>{initials(name || '')}</AvatarInitials>;
  } else if (src) {
    bgColor = 'white';
    if (imageLoading) {
      avatarContents = <Skeleton width={'100%'} height={'100%'} />;
    } else {
      avatarContents = <img src={src} alt={name} width="100%" height="100%" />;
    }
  } else if (children) {
    avatarContents = children;
    if (color) {
      bgColor = color;
    }
  } else {
    avatarContents = <AvatarInitials>{initials(name || '')}</AvatarInitials>;
  }

  const conditionalProps: {title?: string} = {};
  if (!disableTitle) conditionalProps.title = name;

  return (
    <AvatarContainer
      {...conditionalProps}
      aria-label={name}
      bgColor={bgColor}
      fgColor={fgColor}
      size={size}
      className={className}
    >
      {avatarContents}
    </AvatarContainer>
  );
};
