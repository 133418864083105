import styled from '@emotion/styled';
import {getMainFont} from '../helpers/getMainFont';
import {Colors} from '../Color';
import {CoreFontSize, CoreFontWeight} from '../Typography/constants';

import {
  BORDER_RADIUS,
  BORDER_SETTING,
  CELL_LINE_HEIGHT,
  DEFAULT_CELL_PADDING,
} from './constants';

type TableHeaderProps = {
  cellPadding?: number;
};

export const TableHeader = styled.th<TableHeaderProps>`
  background-color: ${Colors.Tan30};
  padding: ${({cellPadding = DEFAULT_CELL_PADDING}) => ` ${cellPadding}px`};
  text-align: left;
  line-height: ${CELL_LINE_HEIGHT}px;
  color: ${Colors.Gray100};
  white-space: nowrap;
  font-family: ${({theme}) => getMainFont(theme)};
  font-size: ${CoreFontSize.s16};
  font-weight: ${CoreFontWeight.Medium};
  border-top: ${BORDER_SETTING};

  &:first-of-type {
    border-left: ${BORDER_SETTING};
    border-top-left-radius: ${BORDER_RADIUS}px;
  }

  &:last-of-type {
    border-right: ${BORDER_SETTING};
    border-top-right-radius: ${BORDER_RADIUS}px;
  }
`;
