import React, {FunctionComponent, PropsWithChildren} from 'react';
import {TableBase, TableBaseProps} from './TableBase';
import {TableData} from './TableData';
import {TableHeader} from './TableHeader';
import {TableRow} from './TableRow';

const TableContainer: FunctionComponent<PropsWithChildren<TableBaseProps>> = ({
  children,
  ...props
}) => {
  return <TableBase {...props}>{children}</TableBase>;
};

export const Table = Object.assign(TableContainer, {
  Th: TableHeader,
  Td: TableData,
  Tr: TableRow,
});
