import styled from '@emotion/styled';
import {Colors} from '../Color';
import {BORDER_RADIUS} from './constants';

type TableRowProps = {
  isDisabled?: boolean;
  isDefault?: boolean;
};

export const TableRow = styled.tr<TableRowProps>`
  color: ${({isDisabled}) => (isDisabled ? Colors.Gray40 : Colors.Gray100)};
  ${({isDefault}) => isDefault && `background-color: ${Colors.Tan5};`}

  &:last-of-type {
    > td:first-of-type {
      border-bottom-left-radius: ${BORDER_RADIUS}px;
    }

    > td:last-of-type {
      border-bottom-right-radius: ${BORDER_RADIUS}px;
    }
  }
`;
