import styled from '@emotion/styled';

export type TableBaseProps = {
  columnWidths?: string[];
  width?: string;
};

export const TableBase = styled.table<TableBaseProps>`
  width: ${({width = '100%'}) => `${width}`};
  border-collapse: separate;
  border-spacing: 0;

  ${({columnWidths = []}) =>
    columnWidths.reduce(
      (styleString, columnWidth, index) => `
      ${styleString}
      th:nth-of-type(${index + 1}),
      td:nth-of-type(${index + 1}) {
        width: ${columnWidth};
      }
    `,
      ''
    )}
`;
