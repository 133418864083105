import styled from '@emotion/styled';
import {getMainFont} from '../helpers/getMainFont';
import {Colors} from '../Color';
import {CoreFontSize, CoreFontWeight} from '../Typography/constants';

import {
  BORDER_SETTING,
  CELL_LINE_HEIGHT,
  DEFAULT_CELL_PADDING,
} from './constants';

type TableDataProps = {
  isLoading?: boolean;
  onClick?: () => void;
  cellPadding?: number;
};

export const TableData = styled.td<TableDataProps>`
  border-bottom: ${BORDER_SETTING};
  padding: ${({cellPadding = DEFAULT_CELL_PADDING}) => ` ${cellPadding}px`};
  text-align: left;
  line-height: ${CELL_LINE_HEIGHT}px;
  font-family: ${({theme}) => getMainFont(theme)};
  font-size: ${CoreFontSize.s16};
  font-weight: ${CoreFontWeight.Regular};

  &:first-of-type {
    border-left: ${BORDER_SETTING};
  }

  &:last-of-type {
    border-right: ${BORDER_SETTING};
  }

  ${({isLoading}) =>
    isLoading
      ? `
    background-color: ${Colors.Tan5};
  `
      : ''}

  // TODO: color not in DS?!?!
  ${({onClick}) =>
    onClick
      ? `
    cursor: pointer;

    :hover {
      color: ${Colors.Maroon100};
    }
  `
      : ''}
`;
