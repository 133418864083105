import React, {FunctionComponent} from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {omit, pick} from '@styled-system/props';
import {FlexboxProps, LayoutProps} from 'styled-system';
import {Colors} from '../Color';
import {Input} from '../Input';
import {ComponentProps} from '../custom-types';
import {Column} from '../Column';
import {FieldError} from '../FieldError';
import {getMainFont} from '../helpers/getMainFont';

// See https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements
const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const Container = styled(Column)`
  justify-content: start;
`;

type LabelProps = {
  show?: boolean;
};

export const StyledLabel = styled.label<LabelProps>`
  font-family: ${({theme}) => getMainFont(theme)};
  font-size: 16px;
  color: ${Colors.Gray100};
  ${(props) => !props.show && visuallyHidden}
`;

type InputFieldProps = {
  label: string;
  id: string;
  showLabel?: boolean;
  inputWidth?: string;
  errorText?: string;
} & ComponentProps<typeof Input> &
  FlexboxProps &
  LayoutProps;

export const InputField: FunctionComponent<InputFieldProps> = ({
  label,
  id,
  showLabel,
  inputWidth,
  errorText,
  error,
  ...rest
}) => {
  // All styled system props should be forwarded to the wrapper and not the child input.
  const wrapperProps = pick(rest); // styled system props
  const inputProps = omit(rest); // my props

  return (
    <Container {...wrapperProps}>
      <StyledLabel show={showLabel} htmlFor={id}>
        {label}
      </StyledLabel>
      <Input id={id} width={inputWidth} error={error} {...inputProps} />
      {error && errorText && <FieldError errorText={errorText} />}
    </Container>
  );
};
