import React, {useRef, useEffect} from 'react';
import {Button as ButtonRaw} from '../Button';
import {Body04} from '../Typography/Body';
import {Heading02} from '../Typography/Heading';
import {Colors} from '../Color';
import styled from '@emotion/styled';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  onOutsideClick?: () => void;
}

/**
 * A component which calls the `onOutsideClick` callback when users
 * click outside the UI.
 */
const HandlesOutsideClicks: React.FC<Props> = ({onOutsideClick, ...props}) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !dialogRef.current?.contains(event.target as HTMLElement) &&
        onOutsideClick
      ) {
        onOutsideClick();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dialogRef, onOutsideClick]);
  return <div ref={dialogRef} {...props} />;
};

const DialogContainer = styled(HandlesOutsideClicks)`
  background-color: ${Colors.White0};
  border-radius: 8px;
  padding: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15), 0px 2px 12px rgba(0, 0, 0, 0.06);
  position: fixed;
  z-index: 2;
`;

const Title = styled(Heading02)`
  margin-bottom: 24px;
`;
const Description = styled(Body04)`
  margin-bottom: 24px;
`;

/**
 * Section container for buttons.
 */
const ActionsSection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled(ButtonRaw)`
  margin-left: 8px;
`;

const Overlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1;
`;

export const Dialog = Object.assign(DialogContainer, {
  Overlay,
  Title,
  Description,
  ActionsSection,
  Button,
});
