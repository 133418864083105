"use strict";
exports.__esModule = true;
exports.SvgX = void 0;
var React = require("react");
var SvgWrapper_1 = require("./../SvgWrapper");
/**
 * @deprecated This icon is deprecated. Please use a non-v2 alternative.
 */
var SvgX = function (props) { return (<SvgWrapper_1.default {...props} aspectRatio={1}>
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M19 19L12 12M12 12L5 19M12 12L5 5M12 12L19 5" stroke={props.color || "#10293C"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  </SvgWrapper_1.default>); };
exports.SvgX = SvgX;
exports["default"] = exports.SvgX;
