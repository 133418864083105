import React, {FunctionComponent, CSSProperties} from 'react';
import styled from '@emotion/styled';
import {Box} from '../Box';
import {Colors} from '../Color';
import {Body06} from '../Typography/Body';
import CircleWarning from '@robinpowered/icons/v2/CircleWarning';
import {space, SpaceProps} from 'styled-system';
import {pick} from '@styled-system/props';

type ErrorBadgeProps = {
  message: string;
  style?: CSSProperties;
  politenessSetting?: 'polite' | 'assertive' | 'off';
} & SpaceProps;

const Container = styled(Box)<ErrorBadgeProps>`
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 4px;
    position: relative;
  }
  ${space}
`;

export const ErrorBadge: FunctionComponent<ErrorBadgeProps> = ({
  message,
  style,
  politenessSetting = 'off',
  ...rest
}: ErrorBadgeProps): JSX.Element => {
  const wrapperProps = pick(rest); // styled system props

  return (
    <Container
      aria-live={politenessSetting}
      px="12px"
      py="8px"
      display="flex"
      borderRadius="8px"
      color={Colors.White0}
      backgroundColor={Colors.RedProduct}
      style={style}
      {...wrapperProps}
    >
      <CircleWarning size={24} color={Colors.White0} />
      <Body06 color={Colors.White0}>{message}</Body06>
    </Container>
  );
};
