import React, {CSSProperties} from 'react';
import {Colors} from '../Color';
import {Row} from '../Row';
import {Body05} from '../Typography/Body';
import styled from '@emotion/styled';
import {SpaceProps} from 'styled-system';
import {pick, omit} from '@styled-system/props';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  handleClick: (event: MouseEvent | KeyboardEvent) => void;
  indeterminate?: boolean;
  inputStyle?: CSSProperties;
  labelStyle?: CSSProperties;
}

/**
 * This stylized Checkbox component accepts an optional label, a handleClick event,
 * and several style props if further customization is needed.
 *
 * Disabled and indeterminate states are also available.
 */
export const Checkbox = (props: CheckboxProps & SpaceProps): JSX.Element => {
  const {
    id,
    label,
    handleClick,
    disabled = false,
    indeterminate = false,
    inputStyle,
    labelStyle,
    ...rest
  } = props;
  const inputProps = omit(rest); // InputHTMLAttribute props
  const spaceProps = pick(rest); // Styled System props

  // ensures a user can select/deselect with keyboard
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleClick(e);
    }
  };

  return (
    <StyledGroup
      alignItems="center"
      disabled={disabled}
      indeterminate={indeterminate}
      {...spaceProps}
    >
      <StyledInput
        type="checkbox"
        id={id}
        disabled={disabled}
        style={inputStyle}
        onClick={handleClick}
        // the below onChange silences this issue: https://github.com/facebook/react/issues/13171
        // eslint-disable-next-line max-len
        // it's also important that we favor onClick for testing: https://github.com/testing-library/react-testing-library/issues/275
        onChange={() => null}
        onKeyDown={handleKeyDown}
        {...inputProps}
      />
      {!!label && (
        <StyledLabel
          pl={2}
          color={Colors.Gray100}
          htmlFor={id}
          style={labelStyle}
        >
          <CustomLabel style={labelStyle}>{label}</CustomLabel>
        </StyledLabel>
      )}
    </StyledGroup>
  );
};

const StyledInput = styled.input<{style?: CSSProperties}>`
  appearance: none;
  margin: 0;

  width: 18px;
  height: 18px;
  border: 1px solid ${Colors.Tan70};
  border-radius: 3px;

  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: ${Colors.White0};
  }

  &:checked,
  &:indeterminate {
    background-color: ${Colors.Maroon100};
  }

  &:checked::before {
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(1);
  }

  &:indeterminate::before {
    clip-path: polygon(100% 35%, 100% 60%, 0% 60%, 0% 35%);
    transform: scale(1);
  }

  &:disabled {
    background-color: ${Colors.Tan30};
  }

  &:disabled:checked,
  &:disabled:indeterminate {
    background-color: ${Colors.Tan70};
  }

  &:disabled::before {
    background-color: ${Colors.Gray40};
  }
`;

const StyledBody05 = styled(Body05)`
  line-height: 18px;
  user-select: none;
`;

const StyledLabel = StyledBody05.withComponent('label');

const CustomLabel = styled.div<{style?: CSSProperties}>``;

const StyledGroup = styled(Row)<{
  disabled: boolean;
  indeterminate: boolean;
}>`
  ${StyledInput},
  ${StyledLabel} {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  ${({indeterminate}) =>
    indeterminate &&
    `
    ${StyledInput}::before {
      clip-path: polygon(100% 35%, 100% 60%, 0% 60%, 0% 35%);
      transform: scale(1);
    }
    ${StyledInput} {
      background-color: ${Colors.Maroon100};
    }
    ${StyledInput}:disabled {
      background-color: ${Colors.Tan70};
    }
  `}
`;
