"use strict";
exports.__esModule = true;
exports.SvgChevronDownOutline = void 0;
var React = require("react");
var SvgWrapper_1 = require("./SvgWrapper");
var SvgChevronDownOutline = function (props) { return (<SvgWrapper_1.default {...props} aspectRatio={1}>
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 7.5L12 16.5L21 7.5" stroke={props.color || "#0E1924"} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </SvgWrapper_1.default>); };
exports.SvgChevronDownOutline = SvgChevronDownOutline;
exports["default"] = exports.SvgChevronDownOutline;
